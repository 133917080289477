import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import descriptionData from "../content/description.json";
import { Helmet } from "react-helmet"

const About = () => {
  return (
    <Layout>      
      <Helmet>
        <link rel="canonical" href="https://liveinbrisbane.com.au/about/" />
        <title>About Live in Brisbane</title>
        <meta name="description" content="Live in Brisbane offers comprehensive guides and up-to-date information about living in Brisbane. Whether you're interested in the cost of living, housing, jobs, education, or the local culture, we provide everything you need to make your move. Discover authentic stories from locals and expats to get a real taste of the Brisbane lifestyle."/>        
      </Helmet>

      <div class="banner-container">
        <img src="https://cdn.shopify.com/s/files/1/0507/6697/1031/files/alice-duffield-fgy0t-QHLcQ-unsplash.jpg?v=1701157222" alt="Live in Brisbane - Southbank"/>
      </div>


      <div className="descriptions-container container px-18 max-w-screen-xl mx-auto">
        <h1 class="text-2xl mt-5 mb-5 font-bold">About Live in Brisbane</h1>
        <p>
        Live in Brisbane is your ultimate guide to everything you need to know about life in this bustling Australian city. Our website aims to provide comprehensive and up-to-date information for individuals and families considering a move to Brisbane. From cost of living, real estate options, job opportunities, to education facilities, outdoor activities, arts and culture scene, and more, we got you covered. In addition, we feature authentic stories from locals and expats to give you a real taste of the Brisbane lifestyle. With "Live in Brisbane", making informed decisions about your relocation has never been this easy.
        </p>

        <p>
        We explore the cost of living by distilling complex data into tangible, easy-to-understand breakdowns. We investigate real estate options, creating an inspiring portfolio of the extraordinary places you could call home. With Brisbane's property market catering to diverse tastes and budget ranges, everyone from the modern loft enthusiasts to suburban family home seekers will find alluring options.
        </p>

        <p>
        Beyond material and professional aspects, we believe the soul of city life lies in its people and shared experiences. Thus, we dig into the educational institutions, art and culture scenes, and outdoor activities. Whether you're a parent seeking the best education for your children, a culture enthusiast drawn to theatric stages and museum halls, or an outdoorsy type pulled by the call of the wild, Brisbane offers something for everyone.
        </p>

        <p>
        As you navigate through our pages, our aim is to help you understand what it means to be a part of the vibrant community of Brisbane. Ultimately, we want you to feel an intrinsic connection, a growing desire to be a part of this remarkable city. We've made the nuanced task of deciding your move enticingly simple.
        </p>

        <p>
          Say 'yes' to a new life chapter in Brisbane, where the sun shines brightly on boundless opportunities and radiant experiences. Welcome to 'Live in Brisbane' - your ultimate guide to propelling your life forward in this incredible city.
        </p>
        
      </div>
    </Layout>
  );
};


export const Head = () => (
  <Seo
    title={descriptionData.archive_party.pageTitle}
    description={descriptionData.archive_party.pageDescription}
  />
);

export default About;
